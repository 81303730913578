<template>
  <div>
    <el-dialog
    title="流程记录"
    :visible.sync="dialogVisible2"
    width="70%"
    :before-close="handleClose2">
    <table-page
        :data="tableData"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :border="true"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelection"
      >
      </table-page>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose2">取 消</el-button>
      <el-button type="primary" @click="handleClose2">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>

import TablePage from "@/components/table/table-page";
import { ProcessRecordColumn } from "./tableColumn";
export default {
  components: {
    TablePage,
  },
  data(){
    return{
      dialogVisible2:true,
      tableData: [
        {
          orderType:'237288743',
          cropCode:'资源资产流转审批',
          landArea:'村集体审批',
          estimatePrice:'发起人',
          orderState:'任务创建时间',
          createTime:'流程进度',

        },
        {
          orderType:'237288743',
          cropCode:'资源资产流转审批',
          landArea:'村集体审批',
          estimatePrice:'发起人',
          orderState:'任务创建时间',
          createTime:'流程进度',

        },
        {
          orderType:'237288743',
          cropCode:'资源资产流转审批',
          landArea:'村集体审批',
          estimatePrice:'发起人',
          orderState:'任务创建时间',
          createTime:'流程进度',

        },
        {
          orderType:'237288743',
          cropCode:'资源资产流转审批',
          landArea:'村集体审批',
          estimatePrice:'发起人',
          orderState:'任务创建时间',
          createTime:'流程进度',

        },

      ],
      query: {
        pnum: 1,
        psize: 10,
      },
      columns: ProcessRecordColumn,

  }},
  methods:{
    handleClose2() {
      this.$emit("closeDialog2", false);
    },
  }
};
</script>
